import SIEM from '../assets/images/products/nav/A-SIEM.png';
import SOC from '../assets/images/products/nav/A-SOC.png';
import SAT from '../assets/images/products/nav/A-SAT.png';
import PHISH from '../assets/images/products/nav/A-PHISH.png';
import GRC from '../assets/images/products/nav/A-GRC.png';
import SSB from '../assets/images/products/nav/A-SSB.png';
import SHC from '../assets/images/products/nav/A-SHC.png';
import SECaaS from '../assets/images/products/nav/A-SECaaS.png';
import training from '../assets/icons/training.png';
import blog from '../assets/icons/blog.png';
import whitepapers from '../assets/icons/whitepapers.png';
import newsletters from '../assets/icons/newsletters.png';
import reports from '../assets/icons/reports.png';
import events from '../assets/icons/events.png';
import webinars from '../assets/icons/webinars.png';
import toolkit from '../assets/icons/toolkit.png';
import support from '../assets/icons/support.png';



export const solutions = {
    cyberSecurity: [{
            navItem: 'Access Wireless',
            link: 'cybersecurity/secure-access-wireless',
        },
        { navItem: 'APT Detection	', link: 'cybersecurity/apt-detection' },
        {
            navItem: 'Application Security',
            link: 'cybersecurity/application-security',
        },
        {
            navItem: 'Continuous Monitoring',
            link: 'cybersecurity/continuous-monitoring',
        },
        {
            navItem: 'Cyber Assurance Program	',
            link: 'cybersecurity/cyber-assurance-program',
        },
        {
            navItem: 'Cyber Security',
            link: 'cybersecurity/cyber-security',
        },
        {
            navItem: 'Data Classification',
            link: 'cybersecurity/data-classification',
        },
        {
            navItem: 'Data Loss Prevention',
            link: 'cybersecurity/data-loss-prevention',
        },
        { navItem: 'Data Privacy', link: 'cybersecurity/data-privacy' },
        { navItem: 'DDoS Protection', link: 'cybersecurity/ddos-protection' },
        { navItem: 'DevSecOps', link: 'cybersecurity/devsecops' },
        { navItem: 'Digital Forensic', link: 'cybersecurity/digital-forensic' },
        { navItem: 'Email Security', link: 'cybersecurity/email-security' },
        {
            navItem: 'Endpoint Security',
            link: 'cybersecurity/endpoint-security',
        },
        {
            navItem: 'Forensic & Malware Analysis',
            link: 'cybersecurity/forensic-malware-analysis',
        },
        { navItem: 'Gap Analysis', link: 'cybersecurity/gap-analysis' },
        {
            navItem: 'ID Access Management',
            link: 'cybersecurity/id-access-management',
        },
        { navItem: 'Incident Response	', link: 'cybersecurity/incident-response' },
        {
            navItem: 'Insider Risk Management',
            link: 'cybersecurity/insider-risk-management',
        },
        {
            navItem: 'Insider Threat Data Protection',
            link: 'cybersecurity/insider-threat-data-protection',
        },
        {
            navItem: 'Next-generation Firewalls',
            link: 'cybersecurity/next-generation-firewalls',
        },
        {
            navItem: 'Ransomware Prevention   ',
            link: 'cybersecurity/ransomware-prevention',
        },
        { navItem: 'Sandbox', link: 'cybersecurity/sandbox' },
        {
            navItem: 'Security Assessment',
            link: 'cybersecurity/security-assessment',
        },
        {
            navItem: 'SIEM',
            link: 'cybersecurity/siem',
        },
        {
            navItem: 'Splunk',
            link: 'cybersecurity/splunk',
        },
        {
            navItem: 'SOAR',
            link: 'cybersecurity/soar',
        },
        {
            navItem: 'Vpn gateway',
            link: 'cybersecurity/vpn-gateway',
        },
        {
            navItem: 'Vulnerability Assessment',
            link: 'cybersecurity/vulnerability-assessment',
        },
        {
            navItem: 'Web & Mobile Application Security',
            link: 'cybersecurity/web-mobile-application-security',
        },
        {
            navItem: 'Web Application Firewall',
            link: 'cybersecurity/web-application-firewall',
        },
        { navItem: 'Zero Trust ', link: 'cybersecurity/zero-trust' },
        {
            navItem: 'Cloud App Security Testing',
            link: 'cybersecurity/cloud-app-security-testing',
        },
        {
            navItem: 'Website Penetration Testing',
            link: 'cybersecurity/website-penetration-testing',
        },
        {
            navItem: 'Network Penetration Testing ',
            link: 'cybersecurity/network-penetration-testing',
        },
    ],
    cloudSecurity: [{
            navItem: 'Application Gateway',
            link: 'cloudsecurity/application-gateway',
        },
        {
            navItem: 'Azure DDoS Protection',
            link: 'cloudsecurity/azure-ddos-protection',
        },
        {
            navItem: 'Azure Information Security',
            link: '/cloudSecurity/azure-information-security',
        },
        {
            navItem: 'Cloud Data Protection',
            link: 'cloudsecurity/cloud-data-protection',
        },
        {
            navItem: 'Cloud Protection Services',
            link: 'cloudsecurity/cloud-protection-services',
        },
        {
            navItem: 'Cloud Security Advisory Services',
            link: 'cloudsecurity/cloud-security-advisory-services',
        },
        {
            navItem: 'Cloud Security Assessment',
            link: 'cloudsecurity/cloud-security-assessment',
        },
        {
            navItem: 'Cloud Security Monitoring',
            link: 'cloudsecurity/cloud-security-monitoring',
        },
        {
            navItem: 'Data Security',
            link: 'cloudsecurity/data-security',
        },
        {
            navItem: 'Governance',
            link: 'cloudsecurity/governance',
        },
        {
            navItem: 'Microsoft 365 Security',
            link: 'cloudsecurity/microsoft-365-security',
        },
        {
            navItem: 'Network Security',
            link: 'cloudsecurity/network-security',
        },
        {
            navItem: 'Storage Security',
            link: 'cloudsecurity/storage-security',
        },
    ],
    dataCenter: [
        { navItem: 'Analysis', link: 'datacenter/analysis' },
        {
            navItem: 'Consulting And Engineering Services',
            link: 'datacenter/consulting-and-engineering-services',
        },
        {
            navItem: 'Data Center',
            link: 'datacenter/data-center',
        },
        {
            navItem: 'Disaster Recovery Plan',
            link: 'datacenter/disaster-recovery-plan',
        },
        {
            navItem: 'Energy Efficiency Assessments',
            link: 'datacenter/energy-efficiency-assessments',
        },
        {
            navItem: 'Infrastructure Design',
            link: 'datacenter/infrastructure-design',
        },
    ],
    others: [
        // { navItem: 'Virtualization', link: '/virtualization' },
        { navItem: 'Desktop as a Service', link: '/desktop-as-a-service' },
        { navItem: 'Software as a Service', link: '/software-as-a-service' },
        { navItem: 'Server Virtualization', link: '/server-virtualization' },
        { navItem: 'Backup & DR', link: '/backup-dr' },
        { navItem: 'Data Backup', link: '/data-backup' },
        {
            navItem: 'Aspire Backup and DR Solution',
            link: '/aspire-backup-and-dr-solution',
        },
        { navItem: 'Oracle DR Solutions', link: '/oracle-dr-solutions' },
        {
            navItem: 'Symantec Disaster Recovery',
            link: '/symantec-disaster-recovery',
        },
        { navItem: 'EMC Solution', link: '/emc-solution' },
        { navItem: 'Zetta DataProtect', link: '/zetta-dataprotect' },
        // { navItem: 'Big Data Solution', link: '/big-data-solution' },
        { navItem: 'Business Intelligence', link: '/business-intelligence' },
        // { navItem: 'Cloud Solutions', link: '/cloud-solutions' },
        { navItem: 'Data Warehousing', link: '/data-warehousing' },
        // { navItem: 'Infrastructure Services', link: '/infrastructure-services' },
        { navItem: 'It Consulting', link: '/it-consulting' },
        // { navItem: 'Managed IT Services', link: '/managed-it-services' },
        {
            navItem: 'EPS Analysis and Log Volume Calculator',
            link: '/eps-analysis-and-log-volume-calculator',
        },
    ],

    industries: [
        // { navItem: 'Banking', link: '/banking' },
        // { navItem: 'Government', link: '/government' },
        // { navItem: 'Healthcare', link: '/healthcare' },
        { navItem: 'Insurance', link: '/insurance' },
        { navItem: 'Retail', link: '/retail' },
        { navItem: 'Telecom', link: '/telecom' },
        // { navItem: 'Media & Entertainment', link: '/media-entertainment' },
        { navItem: 'Travel & Hospitality', link: '/travel-hospitality' },
    ],

    software: [
        { navItem: 'Mobile solutions', link: '/mobile-solutions' },
        { navItem: 'HR Management Software', link: '/hr-management-software' },
        { navItem: 'E-commerce', link: '/e-commerce' },
        // { navItem: 'Web Solutions', link: '/web-solutions' },
        // { navItem: 'Hospital & Clinic System', link: '/hospital-clinic-system' },
        { navItem: 'Help Desk Software', link: '/help-desk-software' },
        { navItem: 'Transportation System', link: '/transportation-system' },
        { navItem: 'Mail/Parcels System', link: '/mail-parcels-system' },
    ],
};

export const managedServices = {
    SOCaas: [{
            navItem: 'Security Monitoring',
            link: 'managedservice/SOCaasServices#security-monitoring'
        },
        {
            navItem: 'Incident Response',
            link: 'managedservice/SOCaasServices#incident-response'
        },
        {
            navItem: 'Threat Intelligence',
            link: 'managedservice/SOCaasServices#threat-intelligence'
        },
        {
            navItem: 'Vulnerability Scanning',
            link: 'managedservice/SOCaasServices#vulnerability-scanning'
        },
        {
            navItem: 'Compliance',
            link: 'managedservice/SOCaasServices#compliance'
        },
        {
            navItem: 'Reporting',
            link: 'managedservice/SOCaasServices#reporting'
        },
    ],
    MSSP: [{
            navItem: 'Security Monitoring and Incident Management',
            link: 'managedservice/mssp#security-monitoring-and-incident-management',
        },
        {
            navItem: 'Network Security',
            link: 'managedservice/mssp#network-security'
        },
        {
            navItem: 'Vulnerability Management',
            link: 'managedservice/mssp#vulnerability-management'
        },
        {
            navItem: 'Endpoint Security',
            link: 'managedservice/mssp#endpoint-security'
        },
        {
            navItem: 'Identity & Access Management',
            link: 'managedservice/mssp#identity-access-management',
        },
    ],
    managedIT: [{
            navItem: 'Software Installation And Support',
            link: 'managedservice/managedIt#Software-Installation-And-Support'
        },
        {
            navItem: 'User Management, Authentication, And Single Sign-on',
            link: 'managedservice/managedIt#User-Management-Authentication-And-Single-Sign-on'
        },
        {
            navItem: 'Data Storage, Warehousing, And Management',
            link: 'managedservice/managedIt#Data-Storage-Warehousing-And-Management'
        },
        {
            navItem: 'User Management, Authentication, And Single Sign-on',
            link: 'managedservice/managedIt#Data-Backup-And-Recovery'
        },
        {
            navItem: 'Mobile Device Management',
            link: 'managedservice/managedIt#Mobile-Device-Management'
        },


    ],
    cyberSecurity: [{
            navItem: 'Application Security Testing',
            link: 'managedservice/cybersecurity#application-security-testing',
        },
        {
            navItem: 'Dark Web Monitoring',
            link: 'managedservice/cybersecurity#dark-web-monitoring'
        },
        {
            navItem: 'Endpoint Security',
            link: 'managedservice/cybersecurity#endpoint-security'
        },
        {
            navItem: 'Automative Cyber Security',
            link: 'managedservice/cybersecurity#automative-cyber-security',
        },
        {
            navItem: 'Risk Assessment & Monitoring Services',
            link: 'managedservice/cybersecurity#risk-assessment-monitoring-services',
        },
    ],
    vCISO: [{
            navItem: 'Full Scale vCISO',
            link: 'managedservice/vciso#full-scale-vciso'
        },
        {
            navItem: 'On-Demand vCISO',
            link: 'managedservice/vciso#on-demand-vciso'
        },
        {
            navItem: 'Half-Scale vCISO',
            link: 'managedservice/vciso#half-scale-vciso'
        },
    ],
    cloudSecurity: [{
            navItem: 'Cloud Security Assessment',
            link: 'managedservice/cloudsecurity#cloud-security-assessment',
        },
        {
            navItem: 'Cloud Security Advisory Services',
            link: 'managedservice/cloudsecurity#cloud-security-advisory-services',
        },
        {
            navItem: 'Cloud Protection Services',
            link: 'managedservice/cloudsecurity#cloud-protection-services',
        },
        {
            navItem: 'Cloud Security Monitoring',
            link: 'managedservice/cloudsecurity#cloud-security-monitoring',
        },
    ],
    digitalForensic: [{
            navItem: 'Digital Evidence Recovery',
            link: 'managedservice/digitalforensic#digital-evidence-recovery',
        },
        {
            navItem: 'Cyber Fraud Investigation',
            link: 'managedservice/digitalforensic#cyber-fraud-investigation',
        },
        {
            navItem: 'E-Discovery',
            link: 'managedservice/digitalforensic#e-discovery'
        },
        {
            navItem: 'Incident Response',
            link: 'managedservice/digitalforensic#incident-response'
        },
        {
            navItem: 'Data Breach Forensic',
            link: 'managedservice/digitalforensic#data-breach-forensic'
        },
    ],
    consultancy: [{
            navItem: 'Cyber Security Strategy Development',
            link: 'managedservice/consultancy#cyber-security-strategy-development',
        },
        {
            navItem: 'Cyber Security Health Check',
            link: 'managedservice/consultancy#cyber-security-health-check',
        },
        {
            navItem: 'Security Policy Development',
            link: 'managedservice/consultancy#security-policy-development',
        },
        {
            navItem: 'ISO 27001/2013 Certification',
            link: 'managedservice/consultancy#iso-27001-2013-certification',
        },
        {
            navItem: 'Physical Security Assessment',
            link: 'managedservice/consultancy#physical-security-assessment',
        },
    ],
};
export const singleNavDropdown = {
    products: [
        { navItem: 'A-SIEM', navImage: SIEM, link: 'products/a-siem', navItemFull: 'Aspire Security Information and Event Management' },
        { navItem: 'A-SOC', navImage: SOC, link: 'products/a-soc', navItemFull: 'Aspire Security Operation Center' },
        { navItem: 'A-SAT', navImage: SAT, link: 'products/a-sat', navItemFull: 'Aspire Security Awareness Training' },
        { navItem: 'A-PHISH', navImage: PHISH, link: 'products/a-phish', navItemFull: 'Aspire Phishing Simulation' },
        { navItem: 'A-GRC', navImage: GRC, link: 'products/a-grc', navItemFull: 'Aspire Governance, Risk, and Compliance' },
        { navItem: 'A-SSB', navImage: SSB, link: 'products/a-ssb', navItemFull: 'Aspire Security Score Board' },
        { navItem: 'A-SHC', navImage: SHC, link: 'products/a-shc', navItemFull: 'Aspire Security Health Check' },
        { navItem: 'A-SECaaS', navImage: SECaaS, link: 'products/a-SECaaS', navItemFull: 'Aspire Security as a Service' },
    ],
    partners: [
        { navItem: 'Partner program', link: '/partners/partner-program' },
        { navItem: 'Partner Portal', link: '/partner-portal' },
        { navItem: 'Technology Partners', link: '/technology-partners' },
        { navItem: 'Find a Partner', link: '/find-a-partner' },
    ],
    resources: [
        { navItem: 'Training', navImage: training, navItemFull: 'Training', link: '/resources/training' },
        { navItem: 'Blog', navImage: blog, navItemFull: 'Blog', link: '/resources/blog' },
        { navItem: 'White Papers', navImage: whitepapers, navItemFull: 'White Papers', link: '/resources/whitepaper' },
        { navItem: 'NewsLetters', navImage: newsletters, navItemFull: 'NewsLetters', link: '/resources/newsletters' },
        { navItem: 'Reports', navImage: reports, navItemFull: 'Reports', link: '/resources/reports' },
        { navItem: 'Events', navImage: events, navItemFull: 'Events', link: '/resources/events' },
        { navItem: 'Webinars', navImage: webinars, navItemFull: 'Webinars', link: '/resources/webinars' },
        { navItem: 'Toolkit', navImage: toolkit, navItemFull: 'Toolkit', link: '/resources/free-cybersecurity-resource-kits/cybersecurity-awareness-month-kit' },
        // { navItem: 'Supports', navImage: support, navItemFull: 'Supports', link: '/resources/supports' },
        { navItem: 'Supports', navImage: support, navItemFull: 'Supports', link: '/#get-in-touch' },
    ],
    aboutUS: [
        { navItem: 'About Aspire', link: '/company/about' },
        { navItem: 'News', link: '/company/news' },
        { navItem: 'Press', link: '/company/press' },
        { navItem: 'Careers', link: '/company/careers' },
        { navItem: 'Contact', link: '/company/contact' },
    ],
};